.search-row-width {
    width: 60%;
}

.gov-alphabet {
    padding: 5px;
    .gov-button {
        padding: 1px;
    }
}


table {
    border: none;
}
