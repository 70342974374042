.gov-breadcrumbs-login {

    strong {
        color: var(--gov-color-grey-light);
    }
}
.vh-100{
    height: 100vh;
}
.vh-80{
    min-height: 80vh;
}

.gov-form {
    min-height: 320px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .gov-form-control {
        width: 75%;

        div {
            display: flex;
        }
    }
}

.gov-form-undertext {
    margin: 0 auto;
    width: fit-content;
}
.error-text{
    margin-top: 10px;
    color: red;
    font-size: 18px;
}

.gov-form-input > p {
    width: 20%;
}

.lost-password {
    display: flex;
    width: 100%;
    justify-content: end;
    align-items: center;
}

.gov-container-login {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: left;
}

.label-error {
    color: red;
}
